import * as type from "./types"

export const fetchLoginData = (params: any) => {
    return { type: type.LOGIN_REQUEST, payload: params }
}

export const resetLoginData = () => {
    return { type: type.RESET_LOGIN, payload: null }
}

export const fetchPasswordData = (params: any) => {
    return { type: type.PASS_CODE_REQUEST, payload: params }
}

export const resetPostCodeData = () => {
    return { type: type.RESET_PASS_CODE, payload: null }
}