import HTMLParse from 'html-react-parser';
import moment from 'moment';

export const parseDataByType = (data: any, type: string) => {
    switch (type) {
        case "date":
            return  moment(data).format('DD/MM/YYYY') ; 
        break;

        default:    
            return HTMLParse(data);
    }
}