import { xanturaLogo } from "../../../assets/icons";
import CTAButton from "./button";
import { DropDown, useLegacySupport } from "../../../components";
import { useMsal } from "@azure/msal-react";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const TopHeader = (props: any) => {

  const legacy = useLegacySupport()

  const { instance } = useMsal();

  const dspCodes:any = useSelector<any>((state) => state.dspCodes);

  const [dspData, setDspData] = useState<any>([]);
  const [dspSelectedItem, setDspSelectedItem] = useState<any>(null);


  useEffect(() => {
    if (dspCodes.isSuccess && dspCodes.data !== null) {
      const data = dspCodes.data
      if (data.defaultSelectedValue) {
        setDspSelectedItem(data.defaultSelectedValue)
      }

      const dspData = data.values.map((item: any, index: number) => {
        return {
          id: index,
          value: item.valueToStore,
          title: item.textToDisplay
        }
      })
      setDspData(dspData)
    }
  }, [dspCodes])

  return (
    <header className="top-header">
      <h1>
        <a href="/" title="Homepage">
          <img src={xanturaLogo} alt="Xantura logo" />
        </a>
      </h1>
      <div className="button-wrapper">
        {/* <CTAButton
          title="CREATE CASE"
          background={true}
          onClick={() => {
            
          }}
        /> */}
        
        <DropDown
          label="DATA PROTOCOL"
          labelExternal={false}

          name={"selectCase"}

          data={dspData}
          defaultSelected={dspSelectedItem}

          onChange={(e) => {
            setDspSelectedItem(e.value)
            props.onChange({type: "dsp", value: e.value})
          }} />
        <CTAButton title="LOG OUT" onClick={() => {
          window.localStorage.clear();
          window.location.reload();
        }} background={true} />
      </div>
    </header>
  );
};

export default TopHeader;
