import { createBrowserRouter } from "react-router-dom";
import Auth from "../views/auth-msal";
import Error404 from "../views/error-404";
import ForgetPassword from "../views/forget-password";

// Create a browser router and define routes
const AuthStack = createBrowserRouter([{
    // Root route ("/")
    path: "/",
    // Renders the Auth component when the user visits the root route
    element: <Auth />,
    // Renders a div with "404" when an error occurs in this route
    errorElement: <Error404 />,
},
{
    // Root route ("/")
    path: "/forgetPassword",
    // Renders the Auth component when the user visits the root route
    element: <ForgetPassword />,
    // Renders a div with "404" when an error occurs in this route
    errorElement: <Error404 />,
}
]);

export default AuthStack;