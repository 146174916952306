// Import required modules and configuration
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import * as msal from "@azure/msal-browser"; 
import useLegacySupport from "./hooks/useLegacySupported";
import { MSAL_CONFIG, MSAL_LOGINREQUEST } from "../config";
import { useEffect, useState } from "react";

const AuthWrapper = (props: any) => {

    // Create a new instance of MSAL
    const msalInstance = new msal.PublicClientApplication(MSAL_CONFIG);

    // Custom hook to check if the browser supports legacy token acquisition
    const legacySupported = useLegacySupport();

    // State for authentication error
    const [authError, setAuthError] = useState<any>(null);

    // Use useEffect to initialize MSAL upon component mounting
    useEffect(() => {
        if (!legacySupported.isLegacySupported) {
            // Call the MSAL initialization function if legacy token acquisition is not supported
            initializeMSAL();
        }
    }, [])

    // Async function to initialize MSAL
    const initializeMSAL = async () => {
        await msalInstance.initialize()

        // Attempt to perform Single Sign-On (SSO)
        await msalInstance.ssoSilent(MSAL_LOGINREQUEST).then((res) => {

            // Set the active account upon successful SSO
            msalInstance.setActiveAccount(res.account)
            props.onTokenAcquired(res.accessToken)
        }).catch((error) => {

            // Fallback to MSAL token acquisition if SSO fails
            msalCall()

        })
    }


    // Function to handle MSAL token acquisition via popup
    const msalCall = async () => {
        try {
            await msalInstance.acquireTokenPopup(MSAL_LOGINREQUEST).then((res) => {
                // Set the active account upon successful token acquisition
                msalInstance.setActiveAccount(res.account)
                props.onTokenAcquired(res.accessToken)
            });
        } catch (e) {
            // Set authentication error state if token acquisition fails
            setAuthError(e)
        }
    }


    return (legacySupported.isLegacySupported ? <div className="legacySupported">{props.children}</div> :
        <MsalProvider instance={msalInstance}>
            {/* Render children components only if user is authenticated */}
            <AuthenticatedTemplate>
                {props.children}
            </AuthenticatedTemplate>


            {/* Display error message or authentication prompt if user is unauthenticated */}
            <UnauthenticatedTemplate>
                {authError !== null ? <div>
                    <h3>Error</h3>
                </div> : <div>
                    <h3>Authentication</h3>
                    <p>Please use your login credentials</p>
                </div>}
            </UnauthenticatedTemplate>
        </MsalProvider>)
}

export default AuthWrapper;