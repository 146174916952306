// TODO: Document this file

import React, { useEffect, useState } from "react";

// interfaces
import { selectFieldProps } from "../interfaces";

const DropDown: React.FC<selectFieldProps> = (props) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>({ id: null, title: "", value: "" });

  const [data, setData] = useState<any>(props.data || []);

  useEffect(() => {
    if (props.data !== null) {
      const item = data.findIndex((item: any) => item.value === props.defaultSelected);
      if (item !== -1) {
        setSelectedValue(data[item]);
      }

      setData(props.data);
    }
  }, [props])

  const __openDropdown = () => {
    setIsOpen(!isOpen);
  };


  const __selectOption = (e: any, item: any) => {
    e.preventDefault();
    setSelectedValue(item);
  }


  useEffect(() => {
    setIsOpen(false);
    if (selectedValue.id !== null) {
      props.onChange(selectedValue);
    }
  }, [selectedValue])

  return (<>
    <div className="filter-fields">
      {props.labelExternal ? <h3 className="title">{props.label}</h3> : null}
      <div className={isOpen ? "select-container open" : "select-container"}>
        <div className="select-button-wrapper">
          <input type="hidden" value={selectedValue?.value} name={props.name}
            onChange={(e) => {
              // setSelectedValue({ id: 0, title: "", value: "" });
            }} />
          <button onClick={() => __openDropdown()} type={"button"}>{selectedValue?.title !== "" ? selectedValue?.title : ("Select " + props.label)}</button>
        </div>

        {/* condition for the dropDown data  */}
        {isOpen ? (
          <ul className="dropdown-list">
            {data.map((item: any, index: number) => {
              return (
                <li key={index} className="item">
                  <button onClick={(e) => __selectOption(e, item)}
                    type={"button"}
                  >{item.title}</button>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
    {isOpen ? <div onClick={() => __openDropdown()} style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, background: "rgba(0,0,0,0)",zIndex: 10000 }}></div> : null}
  </>
  );
};

export default DropDown;
