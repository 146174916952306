import { useEffect, useState } from "react";
import { TOKEN, getData } from "../../utils/Storage";

const useLegacySupport:any = (props:any) => {
    const urlParams = new URLSearchParams(window.location.search).get("coreKey");
    
    const isSummaryOneView =(window.location.pathname === "/caseSummaryOneView" ? true : false);
    
    const [token, setToken] = useState<string | null>(urlParams  || null);
    const [isLegacySupported, setIsLegacySupported] = useState<boolean>((urlParams !== null && urlParams.length > 0) || false);

    useEffect(() =>{
        if (isSummaryOneView) {
            if (urlParams !== null && urlParams.length > 0) {
                setToken(urlParams);
                setIsLegacySupported(true);
            }
        }else {
            setIsLegacySupported(false);
            getData(TOKEN).then((res:any) => {              
                if (res !== null) {
                    setToken(res);
                }
            })
        }
    }, [urlParams, isSummaryOneView]);


    return { isLegacySupported, token, isSummaryOneView }
}

export default useLegacySupport;