const TextField = (props: any) => {
  return (
    <div className={'filter-fields ' + props.additionalClass} key={props.name}>
      {props.labelExternal ? <h3 className="title">{props.label}</h3> : null }
      <input
        type={props.type}
        name={props.name}
        placeholder={props.placeholder !== "" ? props.placeholder : null}
        onChange={(e) => props.onChange(e.target)}
      />
    </div>
  );
};

export default TextField;
