export const CASE_CONFIG_REQUEST = "CASE_CONFIG_REQUEST";
export const CASE_CONFIG_RECEIVE = "CASE_CONFIG_RECEIVE";
export const CASE_CONFIG_FAILURE = "CASE_CONFIG_FAILURE";
export const RESET_CASE_CONFIG = "RESET_CASE_CONFIG";


export const GET_DSP_REQUEST = "GET_DSP_REQUEST";
export const GET_DSP_RECEIVE = "GET_DSP_RECEIVE";
export const GET_DSP_FAILURE = "GET_DSP_FAILURE";
export const RESET_GET_DSP = "RESET_GET_DSP";


export const GET_PICKLIST_REQUEST = "GET_PICKLIST_REQUEST";
export const GET_PICKLIST_RECEIVE = "GET_PICKLIST_RECEIVE";
export const GET_PICKLIST_FAILURE = "GET_PICKLIST_FAILURE";
export const RESET_GET_PICKLIST = "RESET_GET_PICKLIST";

export const GET_DATA_GRID_REQUEST = "GET_DATA_GRID_REQUEST";
export const GET_DATA_GRID_RECEIVE = "GET_DATA_GRID_RECEIVE";
export const GET_DATA_GRID_FAILURE = "GET_DATA_GRID_FAILURE";
export const RESET_GET_DATA_GRID = "RESET_GET_DATA_GRID";

export const GET_CHILD_GRID_REQUEST = "GET_CHILD_GRID_REQUEST";
export const GET_CHILD_GRID_RECEIVE = "GET_CHILD_GRID_RECEIVE";
export const GET_CHILD_GRID_FAILURE = "GET_CHILD_GRID_FAILURE";
export const RESET_GET_CHILD_GRID = "RESET_GET_CHILD_GRID";

export const GET_COHORTS_DATA_REQUEST = "GET_COHORTS_DATA_REQUEST";
export const GET_COHORTS_DATA_RECEIVE = "GET_COHORTS_DATA_RECEIVE";
export const GET_COHORTS_DATA_FAILURE = "GET_COHORTS_DATA_FAILURE";
export const RESET_GET_COHORTS_DATA = "RESET_GET_COHORTS_DATA";