// Import React hooks and Kendo Grid components
import {
    Grid, GridColumn, GridEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridSelectionChangeEvent,
    GridHeaderSelectionChangeEvent,
    getSelectedState
} from "@progress/kendo-react-grid"
import { useEffect, useState, useCallback, useRef } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { parseDataByType } from "../../../utils";
import { SortDescriptor, getter, orderBy } from "@progress/kendo-data-query";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { contextmenu, details_icon } from "../../../assets/icons";

// Interface to type-check pagination states
interface PageState {
    skip: number,
    take: number
}

// Initial pagination state
const initialDataState: PageState = { skip: 0, take: 10 };

// Interface to type-check sort states
const initialSort: Array<SortDescriptor> = [
    { field: "FullName", dir: "asc" },
];
const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
/**
 * KendoGrid component to handle displaying grid data.
 *
 * @param {any} props - The props passed from the parent component.
 * @returns {JSX.Element} - The rendered component.
 */
const KendoGrid: any = (props: any) => {
    const minVisibleColumnCount = 8;

    const _grid = useRef<any>(null);
    const _export = useRef<any>(null);

    // State management
    const [gridData, setGridData] = useState<any>(null);
    const [isPagginated, setIsPaginated] = useState<any>(null);
    const [scrollable, setScrollable] = useState<any>(initialDataState.take > 10 ? "scrollable" : "none");
    const [isHiddenColumn, setIsHiddenColumn] = useState<boolean>(false);
    const [hidenColumnCount, setHidenColumnCount] = useState<number>(0);
    const [selectedState, setSelectedState] = useState<{ [id: string]: boolean | number[]; }>({});

    const [sort, setSort] = useState(initialSort);


    const [page, setPage] = useState<PageState>(initialDataState);
    const [pageSize, setPageSize] = useState<number>(10);

    // Event handler for pagination change
    const pageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent;
        const take =
            targetEvent.value === "All" ? gridData.length : event.page.take;

        event.page.take > 10 ? setScrollable("scrollable") : setScrollable("none");

        if (targetEvent.value) {
            setPageSize(targetEvent.value);
        }
        setPage({
            ...event.page,
            take,
        });
    };


    // Data processing useEffect
    useEffect(() => {

        if (props.gridData.gridRows.length > 0) {
            if (props.gridData.gridColumns.length > minVisibleColumnCount) {
                setIsHiddenColumn(true)
                setHidenColumnCount(props.gridData.gridColumns.length - minVisibleColumnCount)
            }
        }

        __processData()
    }, [props]);

    useEffect(() => {
        __processData()
    }, [isHiddenColumn])

    useEffect(() => {
        setPageSize(props.pageSize.value !== "" ? props.pageSize.value : 10)
    }, [props.pageSize])

    // Function to process grid data
    const __processData = () => {
        try {
            let processedData: any = [];

            if (props.gridData.gridRows.length > 0) {
                props.gridData.gridRows.forEach((res: any, index: number) => {
                    let obj: any = {};
                    obj[DATA_ITEM_KEY] = index;

                    res.values.forEach((tdRes: any, tdIndex: number) => {
                        const colDetails = props.gridData.gridColumns[tdIndex];

                        if (props.gridData.gridColumns.length !== res.values.length) {
                            console.error("Column count and data count mismatch")
                        }


                        if (isHiddenColumn) {
                            if (tdIndex < minVisibleColumnCount) {
                                obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                            }
                        } else {
                            obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                        }
                    });

                    // selection field
                    obj[SELECTED_FIELD] = true;


                    processedData.push(obj);
                })



                setGridData(processedData)


                if (processedData.length > 10) {
                    setIsPaginated(true)
                } else {
                    setIsPaginated(false)
                }
            } else {
                setGridData(null)
            }
        } catch (e) {
            console.log(e)
        }
    }

    // Handling scroll event to fetch more data
    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent;
        if (
            e.target.scrollTop + 10 >=
            e.target.scrollHeight - e.target.clientHeight
        ) {
            const moreData = gridData.splice(0, 10);
            if (moreData.length > 0) {
                setGridData((oldData: any) => oldData.concat(moreData));
            }
        }
    };


    const __renderGridToolbar = () => {
        if (!hidenColumnCount) {
            return null
        }

        return <GridToolbar>

            <div className="export-btns-container">
                <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => exportExcel()}>Export to Excel</button>
            </div>

            <div className="pr-1 d-inline-block"></div>
            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => { }}>Select</button>
            <div className="pr-1 d-inline-block"></div>

            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => {
                setIsHiddenColumn(!isHiddenColumn);
            }}>{hidenColumnCount} {isHiddenColumn ? "+" : "-"}</button>


        </GridToolbar>
    }

    const __onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState: any = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const __onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
        },
        []
    );

    const exportExcel = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const __actionColumn = () => {
        return <td>
            <div className="d-flex">
                <button className="btn" onClick={() => {
                    alert("WIP")
                }}><img src={contextmenu} style={{ width: 20 }} /></button>
                <button className="btn" onClick={() => {
                    alert("WIP")
                }}><img src={details_icon} style={{ width: 20 }} /></button>
            </div>
        </td>

    }

    const __renderColumns = () => {

        // return props.gridData?.gridRows.map((res: any, index: number) => {
        //     res.values.map((tdRes: any, tdIndex: number) => {
        //         const tdCol = props.gridData.gridColumns[tdIndex];

        //         return <GridColumn
        //             key={tdCol.name}
        //             field={tdRes}
        //             title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
        //             width={tdCol.width === 0 ? "auto" : tdCol.width}
        //         />
        //     })
        // })

        const columns: any = []

        props.gridData.gridColumns.map((tdCol: any, index: number) => {
            // optimization for hidden columns

            const grid = <GridColumn
                key={index}
                field={tdCol.name.trim()}
                title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                width={tdCol.width === 0 ? "auto" : tdCol.width}
            />


            if (isHiddenColumn) {
                if (index < minVisibleColumnCount) {
                    columns.push(grid)
                }
            } else {
                columns.push(grid)
            }
        })

        columns.push(<GridColumn cell={() => __actionColumn()} title="Actions" width={100} />)
        columns.push(<GridColumn field={SELECTED_FIELD} title="" />)
        return columns
    }


    // No data, returning empty message
    if (gridData === null) {
        return <div className="p-2">{props.gridData.emptyMessage}</div>
    }



    // Main component render
    return (
        <div>
            {/* Render toolbar  */}
            {__renderGridToolbar()}


            <ExcelExport data={orderBy(gridData.slice(page.skip, page.take + page.skip).map((item: any) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
            })), sort)} ref={_export}>
                <Grid
                    key={props.gridData.gridRows.length}
                    ref={_grid}
                    data={orderBy(gridData.slice(page.skip, page.take + page.skip).map((item: any) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    })), sort)}
                    columnVirtualization={false}

                    style={{ minHeight: '400px', overflowX: "scroll", maxHeight: "65vh" }}
                    scrollable={scrollable}

                    skip={page.skip}
                    take={page.take}
                    total={gridData.length}
                    onPageChange={pageChange}
                    pageSize={pageSize}

                    onScroll={scrollHandler}
                    fixedScroll={true}

                    pageable={isPagginated ? {
                        buttonCount: 10,
                        pageSizes: [10, 25, 50, 100, "All"],
                        pageSizeValue: pageSize,
                    } : false}

                    // dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    onSelectionChange={__onSelectionChange}
                    onHeaderSelectionChange={__onHeaderSelectionChange}

                    sortable={true}
                    sort={sort}
                    onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}

                // editField="inEdit"
                >



                    {/* Render columns */}
                    {__renderColumns()}
                </Grid>
            </ExcelExport>
        </div>
    )
}

export default KendoGrid;