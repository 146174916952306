import * as type from './types';
/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchCaseConfigByDSPId = (params: any) => {
    return { type: type.CASE_CONFIG_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetCaseConfigByDSPId = () => {
    return { type: type.RESET_CASE_CONFIG, payload: null }
}

/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchDSPId = (params: any) => { 
    return { type: type.GET_DSP_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchDSPId = () => {
    return { type: type.RESET_GET_DSP, payload: null }
}


/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchPicklistById = (params: any) => {
    return { type: type.GET_PICKLIST_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchPicklistById = () => {
    return { type: type.RESET_GET_PICKLIST, payload: null }
}


/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchGridDataById = (params: any) => {
    return { type: type.GET_DATA_GRID_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchGridDataById = () => {
    return { type: type.RESET_GET_DATA_GRID, payload: null }
}



/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchChildGridDataById = (params: any) => {
    return { type: type.GET_CHILD_GRID_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchChildGridDataById = () => {
    return { type: type.RESET_GET_CHILD_GRID, payload: null }
}


/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchCohortsDataByFilter = (params: any) => {
    return { type: type.GET_COHORTS_DATA_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchCohortsDataByFilter = () => {
    return { type: type.RESET_GET_COHORTS_DATA, payload: null }
}