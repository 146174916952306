/**
 * Case Summary One View
 * 
 * This component is responsible for rendering the case summary one view
 * 
 * 1. Authentication 
 * The component uses two authentication methods to fetch the data first using the legacy authentication and second using the MSAL authentication.
 * 
 * Legacy Authentication:
 * In case of legacy login system the component reads the token and oneviewrefertence from the url and fetches the data.
 * There are three parameters that are required to fetch the data in case of legacy authentication are as follows:
 * 1. coreKey (token): This is the token that is generated after the user is logged in.
 * 2. oneviewrefertence: This is the oneviewrefertence that is generated after the user is logged in.
 * 3. DspRoleId: This is the role id that is generated after the user is logged in.
 * 4. DspId: This is the dsp id that is generated after the user is logged in.
 * 
 * 
 * MSAL Authentication:
 * In case of MSAL authentication the component reads the caseReference and roleId from the url and fetches the data.
 * Only in case of MSAL authentication the component renders the Information Governance plugin.
 * 
 * 2. Fetch Information Governance Data
 * The Information governance popup will be rendered only in case of MSAL authentication. The component fetches the information governance data from the API. 
 * The API returns the information governance data that is used to render the information governance popup. User need to select the appropriate role to fetch the data. 
 * If the user is not authorized to access the case summary data then the component will render the error message and the application will not proceed further.
 * 
 * 3. Fetch Case Summary Config
 * The component fetches the case summary config data from the API. The API returns the config data that is used to render the case summary one view layout.
 * 
 * 4. Fetch Case Summary Data
 * The component fetches the case summary data from the API. The API returns the case summary data that to be rendered in the case summary one view layout. 
 * API provide the data in object format, each object to be fetched individually and rendered in the layout.
 * 
 * */



// Import dependencies
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import jsPDF from "jspdf";
import { toast } from 'react-toastify';
import { useMsal } from "@azure/msal-react";

// Import action creators
import {
    fetchCaseSummaryConfig,
    resetCaseSummaryConfig,
    fetchCaseSummaryData,
    resetCaseSummaryData,
    updateData,
    fetchInformationGovernanceData,
    resetInformationGovernanceData,
    fetchOverviewreference,
    resetFetchOverviewreference,
    fetchOverviewreferenceAccess,
    resetFetchOverviewreferenceAccess
} from "./store/actions";

// Import components and configuration
import { Layout } from "./components";
import { InformationGovernance, Loader, TwoColumnLayout, useLegacySupport, AuthWrapper } from "../../components";
import { MSAL_LOGINREQUEST } from "../../config"
import { TokenProps } from "../../interfaces";
import { preparePDF } from "../../store/actions/ui-actions";
import { toSeoUrl } from "../../utils/commonUtils";


const Homepage = (props: any) => {

    // Redux hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // React hooks
    const mainContainer = useRef<any>(null);
    const params = useParams();

    // read query params
    const [searchParams, setSearchParams] = useSearchParams();

    // read legacy support 
    const legacySupported = useLegacySupport();

    // MSAL hooks
    const { instance } = useMsal()

    // Redux state selectors
    const caseSummaryConfig: any = useSelector<any>(state => state.caseSummaryConfig);
    const caseSummaryData: any = useSelector<any>(state => state.caseSummaryData);
    const caseData: any = useSelector<any>(state => state.caseData);
    const informationGovernanceData: any = useSelector<any>(state => state.informationGovernanceData);
    const overviewreference: any = useSelector<any>(state => state.overviewreference);
    const overviewreferenceaccess: any = useSelector<any>(state => state.overviewreferenceaccess);

    // Component state variables
    const [demographicsData, setDemographicsData] = useState<any>(null);
    const [mainSectionData, setMainSectionData] = useState<any>(null);
    const [caseConfigDataState, setCaseConfigDataState] = useState<any>(null);
    const [caseDataState, setCaseDataState] = useState<any>(null);

    const [informationGovernanceModel, setInformationGovernanceModel] = useState<Boolean>(true);
    const [isIGAccess, setIGAccess] = useState<Boolean>(false);
    const [informationGovernanceDataState, setInformationGovernanceDataState] = useState<any>(null);
    const [headers, setHeaders] = useState<any>(null);
    const [footer, setFooter] = useState<any>(null);
    const [token, setToken] = useState<TokenProps>();

    const [getAppId, setAppId] = useState<any>();
    const [overviewReferenceId, setOverviewreferenceId] = useState<any>(null);
    const [caseReference, setCaseReference] = useState<any>();
    const [dspRoleId, setDspRoleId] = useState<any>();
    const [dspRoleTitle, setDspRoleTitle] = useState<any>();
    const [dspId, setDspId] = useState<any>();


    useEffect(() => {

        const queryStrings:any = {}
        searchParams.forEach((value, key) => {
            queryStrings[`${key.toLowerCase()}`] = value
        })

        setAppId(queryStrings.roleid || null);
        setCaseReference(queryStrings.casereference || null);
        setOverviewreferenceId(queryStrings.oneviewreference || null);
        setDspRoleId(queryStrings.dsproleid || null);
        setDspId(queryStrings.dspid || null);

        try {
            if (legacySupported.isLegacySupported) {
                let token: any = legacySupported.token;
                setToken({ isLegacy: true, token: token })
            } else {
                // Initialize token and other data
                // instance.acquireTokenPopup(MSAL_LOGINREQUEST).then((res) => {
                //     let token = res.accessToken
                //     setToken({ isLegacy: false, token: token })
                // });
            }

        } catch (e: any) {
            toast.error(e.message)
        }
    }, [])


    useEffect(() => {
        try {
            if (token?.token !== "" && caseReference !== null) {
                /**
                * Dispatch action to fetch information governance data
                *  */
                dispatch(fetchInformationGovernanceData({ token: token, params: { appId: getAppId } }));
            } else if (token?.token !== "" && overviewReferenceId !== null) {
                /**
                * Dispatch action to fetch overview reference access
                *  */

                dispatch(fetchOverviewreferenceAccess({
                    oneViewReference: overviewReferenceId,
                    dspRoleId: dspRoleId,
                    dspId: dspId,
                    token: token
                }));
            }
        } catch (e: any) {
            toast.error(e.message)
        }

    }, [token])


    useEffect(() => {
        if (overviewreferenceaccess.isSuccess && overviewreferenceaccess.data !== null) {

            let data = overviewreferenceaccess.data;

            if (data.accessCaseSummaryFlag === 1) {
                setDspRoleTitle(data.dspRoleIdName);

                dispatch(fetchCaseSummaryConfig({
                    caseSummaryType: data.caseSummaryTypeId,
                    token: token
                }));

            } else {
                toast.error(data.responseMessage)
            }

            dispatch(resetFetchOverviewreferenceAccess())
        } else if (overviewreferenceaccess.isError) {
            toast.error(overviewreferenceaccess.data.error.data || overviewreferenceaccess.data.error.status)
            dispatch(resetFetchOverviewreferenceAccess())
        }
    }, [overviewreferenceaccess])


    useEffect(() => {
        // Process received overview references
        if (overviewreference.isSuccess && overviewreference.data !== null) {
            let data = overviewreference.data;

            setOverviewreferenceId(data.oneViewRef)

            if (data.accessCaseSummaryFlag === 1) {
                setInformationGovernanceModel(false);
                setDspRoleTitle(data.dspRoleIdName);

                dispatch(fetchCaseSummaryConfig({
                    caseSummaryType: data.caseSummaryTypeId,
                    token: token
                }));
            } else {
                toast.error(data.responseMessage)
            }

            dispatch(resetFetchOverviewreference())
        } else if (overviewreference.isError) {
            toast.error(overviewreference.data.error.data.title)
            dispatch(resetFetchOverviewreference())
        }
    }, [overviewreference])


    useEffect(() => {
        // Process received Information Governance data
        try {
            if (informationGovernanceData.isSuccess && informationGovernanceData.data !== null) {
                setInformationGovernanceDataState(informationGovernanceData.data);

                dispatch(resetInformationGovernanceData());
            } else if (informationGovernanceData.isError) {
                toast.error(informationGovernanceData?.data?.error?.data)
                dispatch(resetInformationGovernanceData());
            }
        } catch (e: any) {
            toast.error(e.message)
        }

    }, [informationGovernanceData])

    useEffect(() => {
        // Process received case summary config data
        try {
            if (caseSummaryConfig.isSuccess && caseSummaryConfig.data !== null) {
                const data = caseSummaryConfig.data.panels;
                if (data && data.length > 0) {
                    setCaseConfigDataState(data);
                    setIGAccess(true)

                    data.forEach((item: any) => {
                        switch (item.title) {
                            case "Demographics":
                                setDemographicsData(item);
                                break;

                            case "Data sections":
                                setMainSectionData(item);
                                break;

                            default:
                                return "none"
                        }
                    })
                }

                const headers = caseSummaryConfig.data?.header;
                if (headers) {
                    setHeaders(headers)
                }

                const footer = caseSummaryConfig.data?.footer;
                if (footer) {
                    setFooter(footer)
                }

                dispatch(resetCaseSummaryConfig())
            } else if (caseSummaryConfig.isError) {
                toast.error(caseSummaryConfig.data.error.data)
                dispatch(resetCaseSummaryConfig())
            }
        } catch (e: any) {
            toast.error(e.message)

            dispatch(resetCaseSummaryConfig())
        }

    }, [caseSummaryConfig])


    useEffect(() => {
        // Process received case summary data
        try {
            if (caseSummaryData.isFetching) {
                dispatch(updateData(caseSummaryData.data))
            } else if (caseSummaryData.isSuccess && caseSummaryData.data !== null) {

                let data = caseSummaryData.data;
                // update the case data 
                if (data?.primarySectionId) {
                    dispatch(updateData({ dataId: data.primarySectionId, data: data.tableData }))
                } else if (typeof (data) && data.length > 0) {
                    data.map((item: any) => {
                        dispatch(updateData({ dataId: item.primarySectionId, data: item.tableData }))
                    })
                } 

                // reset the case summary data API
                dispatch(resetCaseSummaryData())
            } else if (caseSummaryData.isError) {
                toast.error(caseSummaryData.data.error.data)
                dispatch(updateData(caseSummaryData))
                dispatch(resetCaseSummaryData())
            }
        } catch (e) {
            dispatch(resetCaseSummaryData())
        }
    }, [caseSummaryData])


    useEffect(() => {
        // Fetch case data if prepopulate is true
        if (isIGAccess) {
            caseConfigDataState.forEach((item: any) => {
                let primarySections = item.primarySections;
                let cases: string[] = [];
                if (primarySections && primarySections.length > 0) {
                    primarySections.forEach((section: any) => {
                        if (section.prePopulate === "Y") {
                            if (section.type === "tabs") {
                                section.subSections.forEach((tab: any) => {
                                    if (tab.prePopulate === "Y") {
                                        // __fetchCaseDataAPI(tab.id, overviewReferenceId);
                                        cases.push(tab.id)
                                    }
                                })
                            } else {
                                // __fetchCaseDataAPI(section.id, overviewReferenceId);
                                cases.push(section.id)
                            }
                        }
                    })
                }

                if (cases.length > 0) {
                    __fetchAllCasesDataAPI(cases, overviewReferenceId)
                }
            })
        }
    }, [isIGAccess])



    useEffect(() => {
        // Process received case data
        try {
            if (caseData.data && caseData.data !== null) {
                setCaseDataState(caseData.data)
            }
        } catch (e: any) {
            toast.error(e.message)
        }
    }, [caseData])

    // Fetch case summary data
    const __fetchCaseDataAPI = (dataId: string, caseId: string) => {
        try {
            dispatch(fetchCaseSummaryData({ dataId: dataId, caseId: caseId, token: token, fetchSelected:false }))
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    // Fetch case summary data
    const __fetchAllCasesDataAPI = (dataId: string[], caseId: string) => {
        try {
            dispatch(fetchCaseSummaryData({
                dataId: dataId.join(","),
                caseId: caseId,
                token: token,
                fetchSelected: true
            }))
        } catch (e: any) {
            toast.error(e.message)
        }
    }

    // Render aside layout
    const __renderAside = () => {
        if (caseSummaryConfig.isFetching) {
            return <Loader />
        }

        return <Layout
            data={demographicsData}
            caseData={caseDataState}
            isAside={true}
            onClickExpand={(id: string) => {
                console.log(id)
            }}
            onClickFetchCaseData={(dataId: string) => {
                __fetchCaseDataAPI(dataId, overviewReferenceId)
            }}
        />
    }

    // Render main layout
    const __renderMain = () => {
        if (caseSummaryConfig.isFetching) {
            return <Loader />
        }

        let title = headers?.title;

        return <>
            <div className="d-flex justify-content-between main-heading">

                <h4 className="pt-2 main-heading-title" title={title}>{title}</h4>

                <div className="pb-3 pt-2 data-button-container">

                    {
                        dspRoleTitle !== null && dspRoleTitle !== "" ? <p title={t("DATA_SHARING_PROTOCALL")} className="btn btn-primary mb-0 data-sharing">{dspRoleTitle}</p> : null
                    }

                    <div className="d-inline-block px-1"></div>

                    {headers?.downloadPDF === "Y" ?
                        <button title={t("DOWNLOAD_PDF")} onClick={() => __printPDF(dspRoleTitle)} className="btn btn-primary btn-download">{t("DOWNLOAD_PDF")}</button> : null
                    }
                </div>
            </div>
            <Layout
                data={mainSectionData}
                caseData={caseDataState}
                isAside={false}
                onClickExpand={(id: string) => {
                    console.log(id)
                }}
                onClickFetchCaseData={(dataId: string) => {
                    __fetchCaseDataAPI(dataId, overviewReferenceId)
                }}
            />

            {
                footer ? <div>
                    <p style={footer.footerStyle}>{footer.text}</p>
                </div> : null
            }

        </>
    }

    // Generate PDF
    const __printPDF = (title: string) => {
        const doc = new jsPDF({
            unit: "px",
            format: [(window.innerWidth * 1.41), window.innerWidth]
        });


        dispatch(preparePDF(true));
        alert("Pleas wait while we are preparing PDF")
        setTimeout(() => {
            doc.html(document.body, {
                callback: function (doc) {
                    let fileName = toSeoUrl((caseReference || overviewReferenceId) + "-" + title)
                    doc.save(fileName + ".pdf");

                    dispatch(preparePDF(false));
                    alert("PDF downloaded successfully");
                },
                x: 10, y: 10
            });
        }, 500)
    }

    // Submit IG Plugin Authentication
    const __submitIgPluginAuth = (type: string, data: any) => {
        try {
            if (type === "closeForm") {
                window.close();
                setInformationGovernanceModel(false)
            } else if (type === "formSubmit") {

                /**
                 * Dispatch action to fetch IG data 
                **/


                setDspRoleId(data.involveCase.id);

                let OverviewReferenceRequest = {
                    caseReference: caseReference,
                    token: token,
                    appId: getAppId,
                    dspRoleId: data.involveCase.id,
                    url: data.url
                }

                /**
                 * Dispatch action to fetch overview reference 
                 **/
                dispatch(fetchOverviewreference(OverviewReferenceRequest));
            }
        } catch (e: any) {
            toast.error(e.message)
        }
    }


    // Error or loading handling
    if (caseSummaryConfig.isError) {
        return (<div>{caseSummaryConfig.data.message}</div>)
    }

    if (caseSummaryConfig.isFetching) {
        return (<div style={{ textAlign: "center" }}>Loading...</div>)
    }

    // Render the component
    return (
        <AuthWrapper onTokenAcquired={(token:string) => {
                console.log ("token: ", token)
                setToken({ isLegacy: false, token: token })
        }}>
        <>
            {caseSummaryConfig.isFetching ? <Loader /> : null}

            {caseConfigDataState !== null ?
                <TwoColumnLayout
                    mainContainer={mainContainer}
                    aside={__renderAside()}
                    main={__renderMain()}
                /> : <Loader />
            }

            {
                informationGovernanceModel && informationGovernanceDataState && informationGovernanceDataState !== null ?
                    <InformationGovernance
                        data={informationGovernanceDataState}
                        isFetching={overviewreference.isFetching}
                        onSubmit={(type: string, res: any) => __submitIgPluginAuth(type, res)} />
                    : null
            }
        </>
        </AuthWrapper>
    )
}

// Export the component
export default Homepage;