// Import dependencies
import { useEffect, useState } from "react";

// import components and configuration
import { TopHeader, CTAButton, KendoGrid, CaseFilter } from "./component";
import {
  DropDown,
  TextField,
  TwoColumnLayout,
  useLegacySupport,
} from "../../components";

import { useDispatch, useSelector } from "react-redux";
import { fetchCaseConfigByDSPId, fetchCohortsDataByFilter, fetchDSPId, fetchGridDataById, fetchPicklistById, resetCaseConfigByDSPId, resetFetchCohortsDataByFilter, resetFetchDSPId, resetFetchGridDataById, resetFetchPicklistById } from "./store/actions";
import { TOKEN, getData } from "../../utils/Storage";
import { toast } from "react-toastify";
import { setSelectedDSPId } from "../../store/actions/ui-actions";
import { perPageCount, viewDrop } from "../../config/const";


const Dashboard = () => {

  const dispatch = useDispatch();

  const legacySupported = useLegacySupport();

  const dspCodes = useSelector((state: any) => state.dspCodes);
  const caseManagementConfig = useSelector((state: any) => state.caseManagementConfig);
  const getPickerListById = useSelector((state: any) => state.getPickerListById);
  const mainGridData = useSelector((state: any) => state.mainGridData);
  const cohortsGridData = useSelector((state: any) => state.cohortsGridData);

  const [token, setToken] = useState<any>({ isLegacy: false, token: "" });
  const [leftSidePanel, setLeftSidePanel] = useState<any>(null);
  const [mainSection, setMainSection] = useState<any>(null);
  const [selectedDSP, setSelectedDSP] = useState<any>(null);
  const [filterList, setFilterList] = useState<any>(null);
  const [gridData, setGridData] = useState<any>(null);
  const [gridPageCount, setGridPageCount] = useState<any>(10);
  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const [gridId, setGridId] = useState<any>(null);

  const [selectedCohortId, setSelectedCohortId] = useState<any>(null);
  const [cohortSearchString, setCohortSearchString] = useState<any>("");

  // constructor(props: any) {
  useEffect(() => {
    getData(TOKEN).then((res: any) => {
      if (res?.provider === "msal") {
        setToken({ isLegacy: false, token: res.token });
      } else {
        setToken({ isLegacy: true, token: res.token });
      }
    })
  }, []);


  useEffect(() => {
    try {
      if (token.token !== "") {
        dispatch(fetchDSPId({ token: token }))
      }
    } catch (e) {
      console.log(e)
    }
  }, [token])


  useEffect(() => {

    if (selectedDSP !== null) {
      // TODO: set selected dsp id
      dispatch(setSelectedDSPId({ dsp: selectedDSP, updated: false }))

      // TODO: fetch case config by default dsp id 
      dispatch(fetchCaseConfigByDSPId({ token: token, dspId: selectedDSP }));


    }
  }, [selectedDSP])

  useEffect(() => {
    try {
      if (dspCodes.isSuccess && dspCodes.data !== null) {
        setSelectedDSP(dspCodes.data.defaultSelectedValue)
        // TODO: set selected dsp id
        dispatch(setSelectedDSPId({ dsp: dspCodes.data.defaultSelectedValue, updated: true }))

        dispatch(resetFetchDSPId())
      } else if (dspCodes.isError) {
        dispatch(resetFetchDSPId())
      }
    } catch (e) {
      console.log(e)
    }

  }, [dspCodes])

  useEffect(() => {
    if (caseManagementConfig.isSuccess && caseManagementConfig.data !== null) {

      const data = caseManagementConfig.data.panels;
      if (data && data.length > 0) {

        data.forEach((item: any) => {
          switch (item.panelId) {
            case 1:
              setLeftSidePanel(item);
              break;

            case 2:
              setMainSection(item);
              break;

            default:
              return null
          }


          item.primarySections.forEach((section: any) => {

            section?.form?.controls.forEach((field: any) => {
              switch (field.type) {
                case "selectpicker":
                  const params: any = {}
                  field.apiPicklistEndpoint.parameters.forEach((item: any) => {
                    params['controlId'] = field.controlId
                    if (item === "dspId") {
                      params["dspId"] = selectedDSP;
                    } else {
                      params[item] = field[`${item}`];
                    }
                  })

                  dispatch(fetchPicklistById({ token: token, param: params, endpoint: field.apiPicklistEndpoint.endpointUrl }))
                  break;

                default:


                  break;

              }
            })

            if (section.type === "datagrid") {
              setGridId(section.dataGrid.dataGridId)
              dispatch(fetchGridDataById({
                token: token,
                param: {
                  gridId: section.dataGrid.dataGridId,
                  dspId: selectedDSP,
                  filtersJson: [
                  ]
                }
              }))
            }
          })

        })
      }

      dispatch(resetCaseConfigByDSPId())
    } else if (caseManagementConfig.isError) {
      toast.error("Something went wrong, please try again later")
      dispatch(resetCaseConfigByDSPId())
    }

  }, [caseManagementConfig])

  // let timerMainGrid: any = null;
  // useEffect(() => {
  //   clearTimeout(timerMainGrid)
  //   timerMainGrid = setTimeout(() => {

  //   }, 300)
  // }, [selectedFilters]);



  useEffect(() => {
    if (getPickerListById.isSuccess && getPickerListById.data !== null) {
      const data = getPickerListById.data;
      setFilterList({ ...filterList, [data?.controlId]: data })

      dispatch(resetFetchPicklistById())
    } else if (getPickerListById.isError) {

      toast.error("Something went wrong, please try again later")
      dispatch(resetFetchPicklistById())
    }
  }, [getPickerListById])


  useEffect(() => {
    if (mainGridData.isSuccess === true && mainGridData.data !== null) {
      setGridData(mainGridData.data)

      dispatch(resetFetchGridDataById())
    } else if (mainGridData.isError) {
      toast.error("Something went wrong, please try again later")
      dispatch(resetFetchGridDataById())
    }
  }, [mainGridData]);

  useEffect(() => {
    if (cohortsGridData.isSuccess === true && cohortsGridData.data !== null) {
      setGridData(cohortsGridData.data)

      dispatch(resetFetchCohortsDataByFilter())
    } else if (cohortsGridData.isError) {
      toast.error("Something went wrong, please try again later")
      dispatch(resetFetchCohortsDataByFilter())
    }
  }, [cohortsGridData]);

  const __setFilterValue = (e: any, controlId: number) => {
    setSelectedFilters((prevState: any) => ({
      ...prevState,
      [controlId]: {
        "controlId": controlId,
        "Values": e.value
      }
    }));
  }

  const __onSubmitFilter = (e: any) => {
    e.preventDefault();

    try {
      if (mainGridData.isFetching || cohortsGridData.isFetching) {
        toast.error("Please wait, data is loading")
      } else {
        dispatch(fetchGridDataById({
          token: token,
          param: {
            gridId: gridId,
            dspId: selectedDSP,
            filtersJson: selectedFilters
          }
        }))
      }
    } catch (e) {
      console.log(e)
    }
  }


  // function to render the aside filter
  const _renderFilterAside = () => {

    let __renderFields: any = [];
    let __renderButtons: any = [];


    leftSidePanel?.primarySections.forEach((item: any, index: number) => {
      if (item.type === "form") {
        return item.form.controls.forEach((field: any, index: number) => {

          switch (field.type) {

            case "selectpicker":
              __renderFields.push(<DropDown
                label={field.label}
                labelExternal={true}
                key={index}

                name={field.name}

                data={filterList && filterList[field.controlId] ? filterList[field.controlId].values : []}
                defaultSelected={selectedFilters && selectedFilters[field.controlId] ? selectedFilters[field.controlId].defaultSelectedValue : ""}

                onChange={(e: any) => __setFilterValue(e, field.controlId)} />
              );

              break;
            case "text":
              __renderFields.push(
                <TextField
                  label={field.label}
                  labelExternal={true}

                  name={field.name}
                  placeholder={field.placeholder}
                  defaultSelected={""}

                  onChange={(e: any) => __setFilterValue(e, field.controlId)} />);

              break;
            case "button":

              __renderButtons.push(<div><CTAButton
                key={index}
                title={field.label}
                background={field.onClick === "callApiAndRefreshPage" ? true : false}
                type={field.onClick === "callApiAndRefreshPage" ? "submit" : "reset"}
              /></div>);

              break;
            default:
              return null

          }
        })
      }
    })


    return (
      <div className="aside-filter-content" key={11}>
        <form method="get" onSubmit={(e) => __onSubmitFilter(e)}>
          <div className="filter-list-container">
            {__renderFields}
          </div>

          {/* action buttons for the aside fields  */}
          <div className="button-container">
            {__renderButtons}
          </div>
        </form>
      </div>
    );
  };

  const __onSubmitCohorts = (e: any) => {
    e.preventDefault();

    try {
      if (mainGridData.isFetching || cohortsGridData.isFetching) {
        toast.error("Please wait, data is loading")
      } else {
        if (selectedCohortId !== null) {
          dispatch(fetchCohortsDataByFilter({
            token: token,
            param: {
              gridId: gridId,
              dspId: selectedDSP,
              cohortId: selectedCohortId,
              searchString: cohortSearchString,
            }
          }))
        }
      }
    } catch (e) {
      console.log(e)
    }

  }


  // function to render the section that incudes the filter and the serach bar
  const __renderMainSection = () => {

    if (mainSection === null) {
      return null
    }
    const cohortsForm: any = [];
    const mainData: any = [];

    mainSection.primarySections.forEach((item: any) => {
      switch (item.type) {
        case "form":

          item.form.controls.forEach((field: any, index: number) => {
            switch (field.type) {

              case "selectpicker":
                cohortsForm.push(<div className="cohorts-filter"><DropDown
                  label={field.label}
                  labelExternal={false}
                  key={index}

                  name={field.name}

                  data={filterList && filterList[field.controlId] ? filterList[field.controlId].values : []}
                  defaultSelected={filterList && filterList[field.controlId] ? filterList[field.controlId].defaultSelectedValue : ""}

                  onChange={(e: any) => setSelectedCohortId(e.value)} /></div>);
                break;
              case "text":

                cohortsForm.push(<TextField
                  label={field.label}
                  labelExternal={false}
                  additionalClass="search-filter"

                  name={field.name}
                  placeholder={field.placeholder}
                  defaultSelected={""}

                  onChange={(e: any) => setCohortSearchString(e.value)} />);

                break;

              case "button":
                cohortsForm.push(<CTAButton
                  key={index}
                  title={field.label}
                  background={field.onClick === "callApiAndRefreshPage" ? true : false}
                  type={field.onClick === "callApiAndRefreshPage" ? "submit" : "reset"}
                />);
                break;

              default:
                return null
            }
          });

          break;

        case "datagrid":
          mainData.push(gridData !== null ? <KendoGrid key={"maingrid"}
            pageSize={gridPageCount}
            gridData={gridData}
          /> : null)
          break;

        default:
          return null
      }
    })

    return (
      <div>
        <form method="get" onSubmit={(e) => __onSubmitCohorts(e)}>
          <div className="search-filter-container">
            {cohortsForm}
          </div>
        </form>

        {/* dropdown option for filtering the case  */}
        <div className="filter-content">
          <div className="filter-header">
            <h3>
              Cases <span>({gridData?.gridRows.length > -1 ? gridData?.gridRows.length : 0} count)</span>
            </h3>
            <div className="select-container">
              <CaseFilter
                name={"field1"}
                list={perPageCount}
                label="Items"
                defaultSelected={gridPageCount}
                onClick={(res) => {
                  setGridPageCount(res)
                }} />
              {/* <CaseFilter name={"field2"} list={itemDrop} title="ITEMS" />
              <CaseFilter name={"field3"} list={list} title="" /> */}
            </div>
            <div className="button-list">
              <button className="btn btn-primary-border" onClick={() => {
                alert("clicked")
              }}>SELECT</button>
            </div>
          </div>
          <div className="content">
            {mainGridData.isFetching ? <div className="loader">
              Loading
            </div> : mainData}
          </div>
        </div>

      </div>
    )

  };


  return (
    // dashboard component
    <div className="dashboard">
      {/* header component  */}
      <TopHeader
        // createCasePopupVal={state.createCasePopup}
        // generateData={setState({ ...state, generateData: false })}
        // protocol={setState({ ...state, protocol: false })}
        // generateVal={state.generateData}

        onChange={(e: any) => {
          setSelectedDSP(e.value)
        }}
      />

      {/* two TwoColumnLayout for dashboard */}

      <TwoColumnLayout
        asideTitle={leftSidePanel?.title}
        aside={_renderFilterAside()}
        main={__renderMainSection()}
      />

      {/* section for the map  */}
      <div className="map">
        <img src="https://placehold.co/40x37" alt="map" />
      </div>

    </div>
  );
};

export default Dashboard;
