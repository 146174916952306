// Note: Auth MSAL Types
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RECEIVE = "LOGIN_RECEIVE";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const RESET_LOGIN = "RESET_LOGIN";

// Note: Auth MSAL Types
export const PASS_CODE_REQUEST = "PASS_CODE_REQUEST";
export const PASS_CODE_RECEIVE = "PASS_CODE_RECEIVE";
export const PASS_CODE_FAILURE = "PASS_CODE_FAILURE";
export const RESET_PASS_CODE = "RESET_PASS_CODE";