const CTAButton = (props: any) => {
  return (
    <button
    {...props}
      className={
        props.background
          ? "btn-cta blue-background"
          : "btn-cta transparent-background"
      }
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      {props.title}
    </button>
  );
};

export default CTAButton;
