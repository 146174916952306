import { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { panel_open, panel_close } from "../assets/icons";


// Functional component for rendering a two-column layout
const TwoColumnLayout = (props: any) => {
  // References to DOM elements
  const asideRef = useRef<any>(null);
  const mainRef = useRef<any>(null);

  // State to track whether the aside is open or not
  const [isAsideOpen, setIsAsideOpen] = useState<boolean>(false);

  // State to track the width of the aside
  const [asideWidth, setAsideWidth] = useState<number>(450);

  // Function to calculate the width of the aside
  const calculateAsideWidth = (): void => {
    let width = window.innerWidth * 0.2;

    if (width > 360) {
      setAsideWidth(width);
    } else {
      setAsideWidth(400);
    }
  };

  // Effect to set initial state and add resize listener
  useEffect(() => {
    try {
      // Set initial state of aside depending on window width
      window.innerWidth < 960 ? setIsAsideOpen(false) : setIsAsideOpen(true);

      // Calculate initial aside width
      calculateAsideWidth();

      // Add resize listener
      window.addEventListener("resize", () => {
        window.innerWidth < 960 ? setIsAsideOpen(false) : setIsAsideOpen(true);
        calculateAsideWidth();
      });
    } catch (e: any) {
      // Display error message as a toast notification
      toast.error(e.message);
    }
  }, []);

  return (
    <div className="case-summary-wrapper" ref={props.mainContainer}>
      {/* Aside column */}
      <aside
        ref={asideRef}
        className={"fixed-aside " + (isAsideOpen ? "open" : "close")}
        style={{ width: isAsideOpen ? `${asideWidth}px` : "38px" }}
      >
        {}
        <div className={"aside-top-bar " + (props.asideTitle !== '' ? "justify-content-sm-between" : "") }>
          {
            props.asideTitle !== '' ? <div className="aside-title">
              <h5>{props.asideTitle}</h5>
            </div> : null
          }
          {/* Button to toggle aside open/close state */}
          <button
            className="toggle btn"
            onClick={() => {
              setIsAsideOpen(!isAsideOpen);
            }}
          >
            <img
              src={isAsideOpen ? panel_close : panel_open}
              alt="arrow"
              style={isAsideOpen ? { width: "1.25rem" } : { width: "1.563rem" }}
            />
          </button>
        </div>
        <div className="aside-content">
          {/* Render aside content if aside is open */}
          {isAsideOpen ? props.aside : null}
        </div>
      </aside>

      {/* Main content column */}
      <main
        ref={mainRef}
        className={"main " + (isAsideOpen ? "open" : "close")}
        style={{ marginLeft: isAsideOpen ? `${asideWidth}px` : 0 }}
      >
        {props.main}
      </main>
    </div>
  );
};

export default TwoColumnLayout;
