// Import required modules and configuration
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';

// Import the action types from the constants file
import * as types from "./types";
import { getNewAxiosInstance } from "../../../utils";
import { toast } from 'react-toastify';
import { selectFieldData } from '../../../interfaces';


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCaseConfigByDSPId(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        let apiEndPoint = `/case-management/config/${res.payload.dspId}`;

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, apiEndPoint);

        // Dispatch action to update state with received data
        yield put({
            type: types.CASE_CONFIG_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.CASE_CONFIG_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchDSPIds(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `/dsp`);

        // Dispatch action to update state with received data
        yield put({
            type: types.GET_DSP_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_DSP_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchPickListItemsByID(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        const api = res.payload.endpoint.slice(4);
        
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, api, { params: res.payload.param });

        const data = response.data.values.map((item: any, index:number) => {
            return { id: index, title: item.textToDisplay, value: item.valueToStore }
        })
        
        response.data.values = data;
        response.data.controlId = res.payload.param.controlId;
        
        // Dispatch action to update state with received data
        yield put({
            type: types.GET_PICKLIST_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_PICKLIST_FAILURE, payload: { error: e } });
    }
}



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchGridDataByID(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, "data/main-grid-data", { params: res.payload.param });

        // Dispatch action to update state with received data
        yield put({
            type: types.GET_DATA_GRID_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_DATA_GRID_FAILURE, payload: { error: e } });
    }
}



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchChildDataByID(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, "data/main-grid-data", { params: res.payload.param });

        // Dispatch action to update state with received data
        yield put({
            type: types.GET_DATA_GRID_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_DATA_GRID_FAILURE, payload: { error: e } });
    }
}

/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCohortsDataByFilter(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, "data/predefined-cohorts", { params: res.payload.param });

        // Dispatch action to update state with received data
        yield put({
            type: types.GET_COHORTS_DATA_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_COHORTS_DATA_FAILURE, payload: { error: e } });
    }
}


/**
 * The main saga watcher. Will fork all other sagas into the middleware.
 */
export default function* dashboardSVOD() {
    yield takeLatest(types.CASE_CONFIG_REQUEST, fetchCaseConfigByDSPId);
    yield takeLatest(types.GET_DSP_REQUEST, fetchDSPIds);
    yield takeLatest(types.GET_DATA_GRID_REQUEST, fetchGridDataByID);
    yield takeLatest(types.GET_CHILD_GRID_REQUEST, fetchChildDataByID);
    yield takeLatest(types.GET_COHORTS_DATA_REQUEST, fetchCohortsDataByFilter);
    yield takeEvery(types.GET_PICKLIST_REQUEST, fetchPickListItemsByID);
}