import React, { useEffect, useState } from "react";
import {  selectValueProps } from "../../../interfaces";


const CaseFilter: React.FC<selectValueProps> = (props:any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSelectedItem, setIsSelectedItem] = useState<any>();

  useEffect(() => {
    props?.list.find((res:any) => {
      if (res?.value === props.defaultSelected) {
        setIsSelectedItem(res);
      }
    })
  }, [props.defaultSelected])
  

  return (
    <div className="case-filter-container" key={props.name}>
      {props.label !== "" ? <span>{props.label}</span> : null}

      <div className="select-container open">
        <div className="select-button-wrapper">
          <button
            className={props.label ? "title-show" : "title-hide"}
            onClick={() => setIsOpen(!isOpen)}
            key={props.name}
          >
            {isSelectedItem !== undefined ? isSelectedItem.title : "SELECT ONE"}
          </button>
        </div>
        {isOpen ? (
          <ul>
            {props?.list.map((res:any) => {
              return (
                <li key={res.value}>
                  <button
                    onClick={(e) => {
                      setIsOpen(false)
                      setIsSelectedItem(res);
                      props?.onClick && props?.onClick(res);
                    }}
                  >{res?.title}</button>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default CaseFilter;
