import { createBrowserRouter } from "react-router-dom";
import Error404 from "../views/error-404";
import Dashboard from "../views/dashboard";
import CaseSummaryOneView from "../views/case-summary-one-view";

// Create a browser router and define routes
const DashboardStack = createBrowserRouter([{
    // Root route ("/")
    path: "/",
    // Renders the Auth component when the user visits the root route
    element: <Dashboard />,
    // Renders a div with "404" when an error occurs in this route
    errorElement: <Error404 />,
},
{
    // Route for case summary one view, capturing multiple URL parameters
    path: "caseSummaryOneView",
    // Renders the CaseSummaryOneView component when the user visits this route
    element: <CaseSummaryOneView />,
    // Renders a div with "404" when an error occurs in this route
    errorElement: <Error404 />,
}]);

export default DashboardStack;