import { combineReducers } from '@reduxjs/toolkit';

// import all common reducers
import uiStateReducers from './ui-state-reducers';


// import all the reducers from the application
import caseSummaryConfigReducer from '../../views/case-summary-one-view/store/reducers/config-reducers'
import caseSummaryDataReducer from '../../views/case-summary-one-view/store/reducers/summary-data-reducers'
import caseDataReducers from '../../views/case-summary-one-view/store/reducers/case-data-reducers';
import informationGovernanceReducers from '../../views/case-summary-one-view/store/reducers/information-governance-reducers';
import igapiReducers from '../../views/case-summary-one-view/store/reducers/igapi-reducers';
import overviewreferenceReducer from '../../views/case-summary-one-view/store/reducers/fetch-overview-reference-reducers';
import overviewreferenceaccessReducer from '../../views/case-summary-one-view/store/reducers/fetch-overview-reference-access-reducers';

import loginReducers from '../../views/auth-msal/store/reducers/login-reducers';
import passcodeReducers from '../../views/auth-msal/store/reducers/passcode-reducer';

import dspCodesReducers from "../../views/dashboard/store/reducers/get-dsp"
import caseManagementConfigReducer from "../../views/dashboard/store/reducers/case-management-config"
import getPickerListByIdReducer from "../../views/dashboard/store/reducers/pick-list"
import mainGridDataReducer from '../../views/dashboard/store/reducers/main-grid-data';
import childGridDataReducer from '../../views/dashboard/store/reducers/child-grid-data';
import cohortsGridDataReducer from '../../views/dashboard/store/reducers/cohorts-grid-data';


// combine all reducers together
const appReducer = combineReducers({
    uiStates: uiStateReducers,
    caseSummaryConfig: caseSummaryConfigReducer,
    caseSummaryData: caseSummaryDataReducer,
    caseData: caseDataReducers,
    informationGovernanceData: informationGovernanceReducers,
    igapiData: igapiReducers,
    overviewreference: overviewreferenceReducer,
    overviewreferenceaccess: overviewreferenceaccessReducer,
    
    loginData: loginReducers,
    passcodeData: passcodeReducers,
    dspCodes: dspCodesReducers,
    caseManagementConfig: caseManagementConfigReducer,
    getPickerListById: getPickerListByIdReducer,
    mainGridData: mainGridDataReducer,
    childGridData: childGridDataReducer,
    cohortsGridData: cohortsGridDataReducer
});


const rootReducer = (state: any, action: any) => {
    // if (action.type === 'IS_LOGOUT') {
    //     state = undefined
    // }
    return appReducer(state, action)
}
export default rootReducer;